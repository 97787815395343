/**
 * Animation durations
 */

.animation--fast {
    --transition-duration: #{ $duration-fast };
}
.animation--slow {
    --transition-duration: #{ $duration-slow };
}
.animation--block {
    --transition-duration: #{ $duration-block };
}
