/**
 * Form label
 *
 * @demo
 *     <label class="form-label">Form label<label>
 */

.form-label {
    position: relative;
    display: block;
    overflow: hidden;
    overflow: clip;
    width: 100%;
    // padding: ...;

    // Prevent text selection when clicked twice
    user-select: none;

    // Styles
    @extend %text--size-input;

    // Disabled label
    input[disabled] + &,
    textarea[disabled] + &,
    select[disabled] + & {
        opacity: 0.5;
    }

    // Required input symbol '*'
    sup {
        // Edge floating label animation fix
        // Without inline-block animation transform origin is broken for label
        display: inline-block;
    }

    // Extended :hover area
    &:before {
        content: "";
        @extend %layout-position-overlay-extended;
    }

    // Hover styles
    .has-hover input:hover ~ & {
        //color: color-use(accent);
    }


    // Error styles
    .has-error &,
    .has-hover .has-error input:hover ~ & {
        color: color-use(error);
    }

}


/**
 * Form label which is always visible
 * and is over the input while input is empty and not focused
 *
 * @demo
 *     <div class="form-control form-control--float ..." data-plugin="inputState">
 *         ...
 *         <label class="form-label">Form label<label>
 *     </div>
 */

.form-control--float {
    position: relative;
}
.form-control--float .form-label {
    position: absolute;
    padding: 0;
    width: auto;
    left: 0;
    top: 15px;

    overflow: hidden;
    overflow: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;

    @include transition((property: (transform, color)));
    transform-origin: 0 0;
}

.form-control--float input,
.form-control--float textarea {
    @include placeholder () {
        // We use placeholder to detect when it's not shown to move label
        // but we don't want to actually show it to the user
        color: transparent !important;
    }
}

// Move label when input is focused or has value
$label-float-normal-font-size: 14;
$label-float-minified-font-size: 20;
$label-float-minified-offset: -42px;

.form-control--float input:focus ~ .form-label,
.form-control--float textarea:focus ~ .form-label,
.form-control--float.is-not-empty .form-label,
.form-control--float.is-focused:not(.form-control--select) .form-label {
    transform: translate(0, #{ $label-float-minified-offset }) scale(#{ $label-float-normal-font-size / $label-float-minified-font-size });
}

// IE11 doesn't support "placeholder-shown" selector, needs to be separate from other selectors
// .form-control--float input:not(:placeholder-shown) ~ .form-label,
// .form-control--float textarea:not(:placeholder-shown) ~ .form-label {
//     transform: translate(0, #{ $label-float-minified-offset }) scale(#{ $label-float-normal-font-size / $label-float-minified-font-size });
//     // color: ...;
// }
