.home__team {
	background-color: #192535;
	color: color-use(text-inverted);

	.team__list {
		display: flex;
		justify-content: center;
		margin: var(--spacing-l) calc(var(--spacing-s) * -1) 0;
	}

	.member {
		flex: 1 1 33.3%;
		padding: 0 var(--spacing-s);
		max-width: 600px;

		&__img {
			text-align: center;
			margin-bottom: var(--spacing-s);
		}

		&__name {
			text-align: center;
			font-weight: $semi-bold;
		}

		&__position {
			text-align: center;
			text-transform: uppercase;
			margin-bottom: var(--spacing-s);
			font-weight: $light;

			@include text--size((
				xs: ( 'font-size': 2.0rem,  'line-height': 24 / 20 * 1em )
			));
		}

		&__exp, &__skill {
			font-weight: $light;

			@include text--size((
				xs: ( 'font-size': 2.4rem,  'line-height': 50 / 24 * 1em )
			));
		}
	}
}