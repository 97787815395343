.home__services {
	background-color: #f7f7f9;

	&__item {
		margin-top: var(--spacing-l);

		&__description {
			display: flex;

			h3 {

			}

			p {
				margin-left: calc(var(--spacing-l) * 2);
			}
		}

		&__tags {
			margin-top: calc(var(--spacing-l) / 2);
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			padding: 0 var(--spacing-s);
		}

		&__tag {
			margin: calc(var(--spacing-s) / 2) calc(var(--spacing-s) / 2);
			background: #EEF6FF;
			border-radius: 20px;
			padding: 0 calc(var(--spacing-s) / 2);
			color: #0075FF;
			display: block;
			white-space: nowrap;
			font-weight: $light;

			@include text--size((
				xs: ( 'font-size': 4.0rem,  'line-height': 90 / 40 * 1em )
			));
		}
	}
}