.footer {
	padding-top: 72px;
	padding-bottom: 18px;
	background-color: #1a1a1a;
	color: #6a6a6a;

	&__nav {
		padding-bottom: 72px;
		font-size: 14px;

		.nav-item {
			margin-bottom: 12px;
		}

		.nav-link {
			padding: 0;
			//@extend %link-decoration-none;
			//@extend %link-style-gray;
		}

		h3 {
			font-size: 14px;
			margin-bottom: 24px;
			color: #b2b2b2;
		}
	}

	&__info {
		padding-top: 18px;
		border-top: 1px solid #454545;
		font-size: 14px;

		a {
			//@extend %link-decoration-none;
			//@extend %link-style-gray;
		}

		&__bot {
			margin-top: 8px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}
}