.home__about {
	text-align: center;

	p {
		margin-top: var(--spacing-s);

		@include text--size((
			xs: ( 'font-size': 6.6rem,  'line-height': 110 / 66 * 1em )
		));

		& + p {
			margin-top: 0;
		}
	}
}