/*
 * Font weight name constants
 * Based on https://github.com/jonathantneal/css-font-weight-names
 */

$thin: 100;
$extra-light: 200;
$ultra-light: $extra-light;
$light: 300;
$regular: 400;
$book: $regular;
$normal: $regular;
$roman: $regular;
$medium: 500;
$semi-bold: 600;
$demi-bold: $semi-bold;
$bold: 700;
$extra-bold: 800;
$ultra-bold: $extra-bold;
$black: 900;
$heavy: $black;
