/**
 * Generic form error message
 * Used to display server connection issues, etc.
 *
 * @demo
 *     <p class="error-message">Error occured</p>
 */

.error-message {
    color: color-use(error);
}


/**
 * Input error label
 * jQuery Validate uses .error class name
 *
 * @demo
 *     <label class="error">This field is required</label>
 */

label.error,
div.error {
    display: block;
    padding: calc(var(--spacing-s) / 2 - 2px) 0 0;

    @extend %text--size-small;
    color: color-use(error);
}


/**
 * Input error style
 */

.form-control--error input {
    border-color: color-use(error);
}
