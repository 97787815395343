.home__intro {
	background: url("#{$path-images}/home-intro.jpg") center center no-repeat #4d4d4d;
	background-size: cover;
	padding-top: calc(var(--spacing-s) / 1.75);
	color: color-use(text-inverted);

	&__heading {
		padding: calc(var(--spacing-l) * 2) var(--spacing-l) calc(var(--spacing-l) * 3.5);
	}

	&__text {
		text-align: center;

		h1, p {
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		}

		p {
			margin-top: var(--spacing-s);
		}
	}
}