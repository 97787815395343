.home__experience {
	p {
		@extend %text--size-large;
	}

	&__numbers {
		display: flex;
		text-align: center;
		margin-top: var(--spacing-l);
		padding: 0 var(--spacing-l);

		> div {
			flex: 1 1 50%;
		}

		p {
			@include text--size((
				xs: ( 'font-size': 3.7rem,  'line-height': 74 / 37 * 1em )
			));
		}
	}

	&__number {
		@include text--size((
			xs: ( 'font-size': 7.5rem,  'line-height': 90 / 75 * 1em )
		));

		margin-bottom: calc(var(--spacing-l) / 2);
	}
}