/**
 * Hide element visually while still being "visible" to the assistive
 * technologies and keyboard input
 *
 * @demo
 *     <select class="is-visually-hidden">...</select>
 */

.is-visually-hidden,
.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    overflow: clip;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;

    // Prevent scrollbars if it's last item on the page
    margin-top: -1px;
}


/**
 * Invisible content
 *
 * Invisible content when JS is enabled
 * Intended to be used with JS animations, to prevent element from visually flashing during load
 *
 * @demo
 *     <div class="is-invisible--js">...</div>
 */

.is-invisible {
    opacity: 0;
    pointer-events: none;
}

.js .is-invisible--js {
    opacity: 0;
    pointer-events: none;
}
.js .is-invisible--lg-up-js {
    @include media-breakpoint(lg-up) {
        opacity: 0;
        pointer-events: none;
    }
}


/**
 * Decorative element
 * User can't interactive with decorative elements
 *
 * @demo
 *     <div class="is-decorative--js">...</div>
 */

.is-decorative {
    pointer-events: none;
}

.js .is-decorative--js {
    pointer-events: none;
}


/**
 * Disable CSS based smooth scrolling
 *
 * @demo
 *     <html class="disable-smooth-scrolling">...</html>
 */

.disable-smooth-scrolling {
    scroll-behavior: initial;
}