/**
 * Hidden element state
 *
 * @demo
 *     <div class="is-hidden">Hidden element</div>
 *     <div class="is-hidden--sm-down">Element hidden only on mobile</div>
 */

.is-hidden,
.is-hidden--xs-up,
.is-hidden--xxxl-down,
.js .is-hidden--js,
.no-js .is-hidden--no-js,
.has-hover .is-hidden--hover,
.no-hover .is-hidden--no-hover {
    display: none !important;
}

@include media-breakpoint('not-print') {
    .is-hidden--not-print,
    .is-visible--print-only {
        display: none !important;
    }
}
@include media-breakpoint('print') {
    .is-hidden--print {
        display: none !important;
    }
}

@each $breakpoint in $media-breakpoints {
    @include media-breakpoint('#{$breakpoint}') {
        .is-hidden--#{$breakpoint} {
            display: none !important;
        }
    }

    @include media-breakpoint('#{$breakpoint}-up') {
        .is-hidden--#{$breakpoint}-up {
            display: none !important;
        }
    }

    @include media-breakpoint('#{$breakpoint}-down') {
        .is-hidden--#{$breakpoint}-down {
            display: none !important;
        }
    }
}

 /*
 * CSS variables for SVG icons to show different shapes on mobile
 */
:root {
    --is-visible--mobile: block;
    --is-visible--non-mobile: block;
    --is-visible--tablet: block;
    --is-visible--non-tablet: block;
    --is-visible--non-desktop: block;
    --is-visible--small-desktop: none;
    --is-visible--large-desktop: none;
    --is-visible--non-any-desktop: block;

    --is-visible--only-mobile: block;
    --is-visible--only-tablet: none;
    --is-visible--only-desktop: none;
    --is-visible--only-non-desktop: block;
}

@include media-breakpoint('lg-up') {
    :root {
        --is-visible--mobile: none;
        --is-visible--only-mobile: none;
    }
}
@include media-breakpoint('md-down') {
    :root {
        --is-visible--non-mobile: none;
    }
}
@include media-breakpoint('lg xl') {
    :root {
        --is-visible--only-tablet: block;
    }
}
@include media-breakpoint('xxl-up') {
    :root {
        --is-visible--tablet: none;
        --is-visible--non-desktop: none;
        --is-visible--only-desktop: block;
        --is-visible--only-non-desktop: none;
    }
}
@include media-breakpoint('xl-down') {
    :root {
        --is-visible--non-tablet: none;
    }
}
@include media-breakpoint('xl') {
    :root {
        --is-visible--non-tablet: none;
        --is-visible--small-desktop: block;
        --is-visible--non-small-desktop: none;
    }
}
@include media-breakpoint('xl-up') {
    :root {
        --is-visible--non-any-desktop: none;
    }
}
@include media-breakpoint('xxl-up') {
    :root {
        --is-visible--large-desktop: block;
        --is-visible--non-large-desktop: none;
    }
}
