$navbar-link-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

.navbar__main {
	margin-bottom: calc(var(--spacing-s) / 1.75);

	&--dark {
		background: #2a2a2a;
		color: #fff;

		.navbar-header {
			a {

			}
		}
	}

	&--light {
		.navbar-header {
			a {

			}
		}
	}

	&--transparent {
		color: #fff;

		.navbar-header {
			a {

			}
		}
	}

	.navbar {
		max-height: 60px;
		min-height: 60px;
		display: flex;
		align-items: center;
	}

	.navbar-header {
		margin-right: 140px;

		a {
			@extend %link--style-invisible;

			@include text--size((
				xs: ( 'font-size': 4.8rem,  'line-height': 60 / 48 * 1em )
			));

			text-shadow: $navbar-link-shadow;
		}
	}

	.navbar-nav {
		display: flex;
		align-items: center;

		a {
			@extend %link--style-header;

			@include text--size((
				xs: ( 'font-size': 3.2rem,  'line-height': 40 / 32 * 1em )
			));

			text-shadow: $navbar-link-shadow;

			& + a {
				margin-left: 90px;
			}
		}
	}

	.navbar__right {
		margin-left: auto;
	}

	.navbar__main__cta {
		padding: 10px 20px;

		@extend %link--style-header;

		@include text--size((
			xs: ( 'font-size': 3.2rem,  'line-height': 40 / 32 * 1em )
		));

		text-shadow: $navbar-link-shadow;
		position: relative;
		display: block;
		white-space: nowrap;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
			border: 2px solid currentColor;
			mix-blend-mode: overlay;
			box-shadow: $navbar-link-shadow;
		}
	}
}