@import "./../mixins/helpers/color";


/*
 * Color Palette
 *
 * To apply color from design system use
 *     color: color-use(cold-1);
 *     color: color-use(warm-2);
 *     color: color-use(green);
 */

$color-design-system: (
    text: #000,
    text-inverted: #ffffff,
    text-muted: #999999,
    small: #868282,

    header-link: #D0C4C4,
    header-link-hover: #FFFFFF,

    white: #FFFFFF,

    print: #000,

    error: #d9534f,
);
