/**
 * Button Sizes
 *
 * Use button sizes to define the hierarhchy between the actions in your page.
 * You may want to move font-size into separate classnames if button size and text size
 * is not related in the design.
 *
 * @demo
 *     <a class="btn">Default button</a>
 *     <a class="btn btn--sm">Small button</a>
 *     <a class="btn btn--lg">Large button</a>
 */

.btn {
    $sizes: (
        xs: (
            'padding':         30px 44px, //fix for safari clipping right side of button https://xqa.one/x/5wgpg.png
            'border-radius':   20px,
        ),
    );

    @include button-sizes ($sizes);
    @include button-square-modifier ($sizes);

    // Text size
    @extend %text--style-caption;
    @extend %text--size-input;
}

.btn--sm {
    $sizes: (
        xs: (
            'height':          30px,
            'border-radius':   15px,
        ),
        md: (
            'height':          40px,
            'border-radius':   20px,
        ),
    );

    @include button-sizes ($sizes);
    @include button-square-modifier ($sizes);
}

.btn--xs {
    $sizes: (
        xs: (
            'height':          20px,
            'border-radius':   10px,
        ),
    );

    @include button-sizes ($sizes);
    @include button-square-modifier ($sizes);
}


// Large button size modifier
.btn--lg {
    $sizes: (
        xs: (
            'height':          calc(var(--spacing-s) * 3),
            'border-radius':   calc(var(--spacing-s) * 1.5),
        ),
        lg: (
            'height':          calc(var(--spacing-s) * 4),
            'border-radius':   calc(var(--spacing-s) * 2),
        ),
    );

    @include button-sizes ($sizes);
    @include button-square-modifier ($sizes);
}

// Medium button size modifier
.btn--md {
    $sizes: (
        xs: (
            'height':          40px,
            'border-radius':   40px,
        ),
        lg: (
            'height':          50px,
        ),
    );

    @include button-sizes ($sizes);
    @include button-square-modifier ($sizes);
}
