.text table {
    width: 100%;
    border-collapse: collapse;

    th {
        font-weight: $text-heading-weight;
        text-align: left;
    }
    td {
        border-top: 1px solid color-use(line);
    }
}
