/**
 * Animations
 *
 * Enables transition for all properties for elements
 *
 * @see components/_animations.scss
 * @demo
 *     div { @extend %transition; }
 */

@mixin transition ($opts: ()) {
    // Edit the values in html/src/stylesheets/settings/_animations.scss
    @if map-has-key($opts, duration) {
        --transition-duration: #{ map-get($opts, duration) };
    }
    @if map-has-key($opts, easing) {
        --transition-easing: #{ map-get($opts, easing) };
    }
    @if map-has-key($opts, property) {
        transition-property: map-get($opts, property);
    }

    transition-duration: var(--transition-duration);
    transition-timing-function: var(--transition-easing);
}

@mixin transition-slow ($opts: ()) {
    // Edit the values in html/src/stylesheets/settings/_animations.scss
    @include transition(map-merge((
        duration: $transition-slow-duration,
        easing: $transition-slow-easing
    ), $opts));
}

@mixin transition-block ($opts: ()) {
    // Edit the values in html/src/stylesheets/settings/_animations.scss
    @include transition(map-merge((
        duration: $transition-block-duration,
        easing: $transition-block-easing
    ), $opts));
}

@mixin transition-button ($opts: ()) {
    // Edit the values in html/src/stylesheets/settings/_animations.scss
    @include transition(map-merge((
        duration: $transition-button-duration,
        easing: $transition-button-easing
    ), $opts));
}

%transition {
    @include transition();
}
%transition-slow {
    @include transition-slow();
}
%transition-block {
    @include transition-block();
}
%transition-button {
    @include transition-button();
}
