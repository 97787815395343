/*
 * Text
 *
 * Settings for text, fonts, sizes
 */

$text-default-font:         "Inter", Helvetica, Arial, sans-serif;

$text-default-weight:       $regular;
$text-default-transform:    none;

$text-heading-weight:       $semi-bold;
$text-heading-transform:    none;

$text-caption-weight:       $semi-bold;
$text-caption-transform:    none;

$text-code-font:            Consolas, "Liberation Mono", Menlo, Courier, monospace;


$text-h1:      (font-size: 16rem,   line-height: 160 / 160 * 1em);
$text-h2:      (font-size: 10.8rem, line-height: 110 / 108 * 1em);
$text-h3:      (font-size: 8rem,    line-height: 80 / 80 * 1em);
$text-h4:      (font-size: 4rem,    line-height: 45 / 40 * 1em);
$text-h5:      (font-size: 2.7rem,  line-height: 35 / 27 * 1em);
$text-h6:      (font-size: 2.0rem,  line-height: 25 / 20 * 1em);

$text-p1:      (font-size: 2.0rem,  line-height: 25 / 20 * 1em);
$text-p2:      (font-size: 1.6rem,  line-height: 22 / 16 * 1em);
$text-p3:      (font-size: 1.4rem,  line-height: 20 / 14 * 1em);
$text-p4:      (font-size: 1.2rem,  line-height: 15 / 12 * 1em);
$text-p5:      (font-size: 1.0rem,  line-height: 12 / 10 * 1em);


/**
 * Text sizes
 *
 * Resolution specific font sizes, line height, etc.
 * All styles cascade up
 *
 * For each style is generated "%text--size-NAME" placeholder selector
 * Each style can also be used included using mixin "@include text--size(NAME);"
 *
 * See extends/_text-styles.scss
 *
 * @example
 *     .my-text-a {
 *         @extend %text--size-lead;
 *     }
 *     // or
 *     .my-text-b {
 *         @include text--size(lead);
 *     }
 */

$text-sizes: (
    xs: (
        'default': (font-size: 3.3rem,  line-height: 60 / 33 * 1em),
        'small':   $text-p5,
        'lead':    (font-size: 4.4rem,  line-height: 60 / 44 * 1em),
        'large':   (font-size: 5.5rem,  line-height: 90 / 55 * 1em),

        'input':   (font-size: 3.4rem,  line-height: 42 / 34 * 1em),

        // Headings
        'h1':      (font-size: 7.3rem,  line-height: 88 / 73 * 1em),
        'h2':      (font-size: 7.0rem,  line-height: 84 / 70 * 1em),
        'h3':      (font-size: 6.6rem,  line-height: 80 / 66 * 1em),
        'h4':      (font-size: 6.4rem,  line-height: 74 / 64 * 1em),
        'h5':      $text-h5,

        // P
        'p1':      $text-p1,
        'p2':      $text-p2,

        // Code
        'code':    ( 'font-size': 1.3rem,  'line-height': 18 / 13 * 1em )
    ),

    print: (
        // Print specific text sizes to override very large font sizes if there are any
    )
);
