// Mixins are loaded after the configuration, explicitly load required functions here
@import "./../mixins/lib/map-get-deep";
@import "./../mixins/lib/str-split";
@import "./../mixins/helpers/media";
@import "./../mixins/helpers/responsive-property";


/*
 * Spacing CSS variables
 */

:root {
    --spacing-s: 20px;
    --spacing-l: 20px;
    --scale:     1px;

    --col: calc(100vw / 2);

    @include responsive-property($media-breakpoint-lg-design, $media-breakpoint-xxl,  '--scale',     0.5px, 1px);

    // Responsive value from lg -> xxl
    @include responsive-property($media-breakpoint-lg-design, $media-breakpoint-xxl,  '--spacing-s', 20px, 20px);
    @include responsive-property($media-breakpoint-lg-design, $media-breakpoint-xxl,  '--spacing-l', 20px, 50px);

    // Responsive value from xxl -> xxxl
    @include responsive-property($media-breakpoint-xxl,       $media-breakpoint-xxxl, '--spacing-s', 30px, 40px);
    @include responsive-property($media-breakpoint-xxl,       $media-breakpoint-xxxl, '--spacing-l', 60px, 100px);

    @include media-breakpoint(lg-up) {
        --col: calc(100vw / 6);
    }
    @include media-breakpoint(xxxl-up) {
        --spacing-s: 40px;
        --spacing-l: 120px;
    }
}


/*
 * Spacing utility classnames
 * From these are generated pt-s-, pb-s-, mt-s-, mb-s-, py-s-, my-s-, pt-l-, pb-l-, ...
 * See settings/_utilities.scss
 */

$spacing-classname-list: (
    '0':   0px,

    's-1': var(--spacing-s),
    's-2': calc(var(--spacing-s) * 2),
    's-3': calc(var(--spacing-s) * 3),
    's-4': calc(var(--spacing-s) * 4),
    's-5': calc(var(--spacing-s) * 5),
    's-5-5': calc(var(--spacing-s) * 5.5),

    'l-1': var(--spacing-l),
    'l-2': calc(var(--spacing-l) * 2),
    'l-3': calc(var(--spacing-l) * 3),
    'l-4': calc(var(--spacing-l) * 4),
    'l-4-5': calc(var(--spacing-l) * 4.5),
    'l-5': calc(var(--spacing-l) * 5),
);


/**
 * Spacings
 *
 * Settings for spacing between most of the elements
 *
 * @see html/src/stylesheets/components/form/_footer.scss
 * @see html/src/stylesheets/components/form/_group.scss
 * @see html/src/stylesheets/components/text/_typography.scss
 * @see html/src/stylesheets/components/text/_lists.scss
 * @demo
 *   @include margins($spacing);
 *   @include margins($spacing-medium);
 *   @include margins-top($spacing);
 *   @include margins-bottom($spacing);
 *   @include margins-top-bottom($spacing);
 */

$spacing: (
    xs:  var(--spacing-s),
);

$spacing-none: (
    xs:  0,
);

// Spacing for headings inside the .text classname
$spacing-heading: (
    xs:  calc(var(--spacing-l) / 50 * 90) 0 calc(var(--spacing-s) / 20 * 35),
);

// Spacing for text inside the .text classname
$spacing-text: (
    xs:  var(--spacing-s),
);

// Spacing for lists inside the .text classname
$spacing-list: (
    xs:  var(--spacing-s),
);
$spacing-list-item: (
    xs:  var(--spacing-s),
);


