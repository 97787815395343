/**
 * List styles
 *
 * By default none of the lists are styled
 */

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}


/**
 * List styles inside text block
 *
 * @demo
 *     <div class="text">
 *         <ul>
 *             <li>List item #1</li>
 *             <li>List item #2</li>
 *             <li>List item #3</li>
 *         </ul>
 *     </div>
 */

.text ul {
    @include margins-top-bottom($spacing-list);

    > li {
        position: relative;
        padding-left: calc(var(--spacing-s) * 2);
        @include margins-top-bottom($spacing-list-item);

        // Line
        &:before {
            position: absolute;
            left: 4px;
            top: 0.8em;
            margin-top: -1px;

            content: "";
            width: 15px;
            height: 1px;
            background: color-use(accent);
        }
    }
}

.text ol {
    @include margins-top-bottom($spacing-list);
    counter-reset: text-ol;

    > li {
        counter-increment: text-ol;
        position: relative;
        padding-left: calc(var(--spacing-s) * 2);
        @include margins-top-bottom($spacing-list-item);

        &:before {
            position: absolute;
            left: 0;
            top: 0;

            content: counter(text-ol) ".";
            color: color-use(accent);

            @extend %text--style-caption;
        }
    }
}
