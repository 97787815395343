/**
 * @name Default text and font styles
 *
 * See extends/_text-styles.scss
 * See extends/_text-sizes.scss
 * See settings/_text.scss
 *
 * @demo
 *     <h1>Heading 1</h1>
 *     <h2>Heading 2</h2>
 *     <h3>Heading 3</h3>
 *     <h4>Heading 4</h4>
 *     <h5>Heading 5</h5>
 *     <p>
 *         Paragraph text
 *     </p>
 *     <p>
 *         <small>Small paragraph text</small>
 *     </p>
 */

html {
    font-size: 62.5%; // 1rem === 10px
}

body {
    @extend %text--size-default;
}

body,
input,
textarea,
button {
    // Color
    color: color-use(text);

    // Fix awkward character spacing
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    font-kerning: normal; // needed for Chrome

    @extend %text--style-default;
}

small {
    @extend %text--size-small;
}


/*
 * Headings
 */

h1, h2, h3, h4, h5 {
    color: color-use(heading);
    @extend %text--style-heading;
}

h1 {
    @extend %text--size-h1;
}
h2 {
    @extend %text--size-h2;
}
h3 {
    @extend %text--size-h3;
}
h4 {
    @extend %text--size-h4;
}
h5 {
    @extend %text--size-h5;
}

sup {
    display: inline-block;

    // Font-weight fix
    @extend %text--style-caption;
    font-size: 63%;

    @include media-breakpoint(lg-up) {
        font-size: 60%;
    }
}


/*
 * Margins and paddings
 */

h1, h2, h3, h4, h5,
hr, p, blockquote, table, iframe, object, pre {
    margin: 0;
}

figure {
    margin: 0;
}


/*
 * Link
 */

a {
    @extend %link--style-default;
}

.link-heading {
    @extend %link--style-heading;
}
