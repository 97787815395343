@import "./../lib/strip-unit";

/**
 * Creates a property which will change proportionally to the viewport width
 * between given values
 *
 * @param {Number} $min-vw - Viewport size from which to start proportional size, either a value
 * @param {Number} $max-vw - Viewport size to which to proportionally size, either a value
 * @param {String} $property - CSS property name
 * @param {Number} $min-value - CSS property value on min size
 * @param {Number} $max-value - CSS property value on max size
 * @param {String} $breakpoint - Optional. Media breakpoint name or list of names

 * @demo
 *     @include responsive-property(320px, 979px, 'font-size', 12px, 16px);
 */

@mixin responsive-property($min-vw, $max-vw, $property, $min-value, $max-value, $breakpoint: null) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-value);
    $u4: unit($max-value);

    @if $min-vw == $max-vw {
        @error 'responsive-property(#{ $min-vw }, #{ $max-vw }, #{ $property }, #{ $min-value }, #{ $max-value }) min and max viewport size is the same';
    }

    @if $u3 != $u4 {
        @if $min-value == 0 {
            $min-value: $max-value * 0;
            $u3: $u4;
        }
        @if $max-value == 0 {
            $max-value: $min-value * 0;
            $u4: $u3;
        }
    }

    @if $u1 == $u2 and $u3 == $u4 {
        @if $min-value == $max-value {
            @if $breakpoint {
                @include media-breakpoint($breakpoint) {
                    #{ $property }: $min-value;
                }
            } @else {
                @media screen and (min-width: $min-vw) and (max-width: $max-vw) {
                    #{ $property }: $min-value;
                }
            }
        } @else {
            & {
                // Value different must be in px units
                $value-diff: $max-value - $min-value;

                @if $u3 == 'rem' or $u3 == 'em' {
                    $value-diff: $value-diff * 10;
                }

                @if $breakpoint {
                    @include media-breakpoint($breakpoint) {
                        #{ $property }: calc(#{$min-value} + #{strip-unit($value-diff)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                    }
                } @else {
                    @media screen and (min-width: $min-vw) and (max-width: $max-vw) {
                        #{ $property }: calc(#{$min-value} + #{strip-unit($value-diff)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                    }
                }
            }
        }
    } @else {
        @error 'responsive-property(#{ $min-vw }, #{ $max-vw }, #{ $property }, #{ $min-value }, #{ $max-value }) incompatible property values';
    }
}
