@import "./../mixins/lib/map-get-deep";

/**
 * Text size / style mixin and placeholder classname generator
 * For each style is generated "%text--size-NAME" placeholder selector
 *
 * @see settings/_text.scss
 * @demo
 *     .class { @extend %text--size-h1; }
 *
 *     .class { @include text--size(h1); }
 *
 *     .class {
 *         @include text--size((
 *             xs:  ( 'font-size': 1.4rem,  'line-height': 24 / 14 * 1em )
 *         ));
 *     }
 */

@mixin text--size-definition ($item) {
    @each $name, $value in $item {
        #{$name}: #{$value};

        @if $name == 'line-height' {
            --lh: #{ $value };
        }
    }

    // Reset first, last child margins
    @if map-has-key($item, 'margin') or map-has-key($item, 'margin-top') {
        &:first-child {
            margin-top: 0;
        }
    }

    @if map-has-key($item, 'margin') or map-has-key($item, 'margin-bottom') {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/**
 * Text size mixin which generates responsive value for font-size, line-height and letter-spacing
 */
@mixin text--size-responsive-definition ($min-vw, $max-vw, $from-item, $to-item, $breakpoints: null) {
    $properties: font-size line-height letter-spacing;

    @each $property in $properties {
        $value-from: map-get($from-item, $property);
        $value-to: map-get($to-item, $property);

        @if $value-from and $value-to {
            @include responsive-property($min-vw, $max-vw, $property, $value-from, $value-to, $breakpoints);
        }
    }
}

/**
 * Text size mixin which generates lg values for font-size, line-height and letter-spacing
 */
@mixin text--lg-responsive-definition ($min-vw, $max-vw, $to-item, $breakpoints: null) {
    $properties: font-size;

    @each $property in $properties {
        $value-from: map-get($to-item, $property) / 1440 * 980;
        $value-to: map-get($to-item, $property);

        @if $value-from and $value-to {
            @include responsive-property($min-vw, $max-vw, $property, $value-from, $value-to, $breakpoints);
        }
    }
}
@mixin text--md-responsive-definition ($min-vw, $max-vw, $from-item, $breakpoints: null) {
    $properties: font-size;

    @each $property in $properties {
        $value-from: map-get($from-item, $property) / 360 * 360;
        $value-to: map-get($from-item, $property) / 360 * 720;

        @if $value-from and $value-to {
            @include responsive-property($min-vw, $max-vw, $property, $value-from, $value-to, $breakpoints);
        }
    }
}

@mixin text--size ($name, $options: (responsive: true)) {
    $arg-type: type-of($name);
    $responsive: map-get($options, responsive);

    @if $arg-type == string {
        // Text size name, eg.    @include text--size(h1);
        @each $breakpoint, $sizes in $text-sizes {
            @include media-breakpoint('#{$breakpoint}-up') {
                $size: map-get($sizes, $name);

                @if $size {
                    @include text--size-definition($size);
                }
            }
        }

        // Responsive text size from xs -> md and lg -> xxl
        @if ($responsive) {
            // $size-xs: map-get-deep($text-sizes, 'xs', $name);
            $size-xxl: map-get-deep($text-sizes, 'xxl', $name);

            // @if $size-xs {
            //     @include text--md-responsive-definition($media-breakpoint-md-design, $media-breakpoint-lg-design, $size-xs);
            // }
            @if $size-xxl {
                @include text--lg-responsive-definition($media-breakpoint-lg-design, $media-breakpoint-xxl, $size-xxl);
            }
        }


    } @else if $arg-type == map {
        // Text size map, eg.    @include text--size((xs: (font-size: 1rem), md: (font-size: 2rem)));
        @each $breakpoint, $size in $name {
            @include media-breakpoint('#{$breakpoint}-up') {
                @include text--size-definition($size);
            }
        }

        // Responsive text size from lg to xxl
        @if ($responsive) {
            // $size-xs: map-get($name, 'xs');
            $size-xxl: map-get($name, 'xxl');

            // @if $size-xs {
            //     @include text--md-responsive-definition($media-breakpoint-md-design, $media-breakpoint-lg-design, $size-xs);
            // }
            @if $size-xxl {
                @include text--lg-responsive-definition($media-breakpoint-lg-design, $media-breakpoint-xxl, $size-xxl);
            }
        }
    }
}


// Itterate and create placeholder classnames for all text sizes
@each $breakpoint, $sizes in $text-sizes {
    @include media-breakpoint('#{$breakpoint}-up') {
        @each $name, $size in $sizes {
            %text--size-#{$name} {
                @include text--size-definition($size);
            }
        }
    }

    // @if $breakpoint == 'xs' {
    //     @each $name, $size in $sizes {
    //         %text--size-#{$name} {
    //             @include text--md-responsive-definition($media-breakpoint-md-design, $media-breakpoint-lg-design, $size);
    //         }
    //     }
    // }
    @if $breakpoint == 'xxl' {
        @each $name, $size in $sizes {
            %text--size-#{$name} {
                @include text--lg-responsive-definition($media-breakpoint-lg-design, $media-breakpoint-xxl, $size);
            }
        }
    }
}
