/**
 * Returns color variable name
 *
 *
 */
@function color-variable ($name, $alpha: 1) {
    @if map-has-key($color-design-system, $name) {
        // From design system
        @if $alpha == 1 {
            @return '--c-#{ $name }';
        } @else {
            @return '--c-#{ $name }-rgb';
        }
    } @else {
        // From theme
        @if $alpha == 1 {
            @return '--t-#{ $name }';
        } @else {
            @return '--t-#{ $name }-rgb';
        }
    }
}

@mixin color-theme-output ($colors) {
    @each $name, $color in $colors {
        // Define --t-...: var(...);
        #{ color-variable($name) }: $color;

        @if str-index($color, 'rgba(') == null {
            // This is hacky, but works
            #{ color-variable($name) }-rgb: str-replace($color, ')', '-rgb)');
        }
    }
}

@mixin color-base () {
    :root {
        @each $name, $color in $color-design-system {
            #{ color-variable($name) }: $color;
            #{ color-variable($name, 0) }: #{ red($color) }, #{ green($color) }, #{ blue($color) };
        }
    }
}

@function color-use ($name, $alpha: 1) {
    @if $alpha == 1 {
        @return var(#{ color-variable($name) });
    } @else {
        @return rgba(var(#{ color-variable($name, $alpha) }), #{ $alpha });
    }
}

@function color-is ($color) {
    @if (type-of($color) == 'color' or $color == currentColor) {
        @return true;
    } @else if (type-of($color) == 'string') {
        @if str-index($color, 'var(--t') != null or str-index($color, 'var(--c') != null {
            @return true;
        } @else {
            @return false;
        }
    } @else {
        @return false;
    }
}
