/**
 * Horizontal ruler
 *
 * @demo
 *     <hr />
 */

hr {
    border: 0;
    border-bottom: 1px solid color-use(line);
}
