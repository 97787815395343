/*
 * Button size
 *
 * Sets different size for different breakpoints
 */

@mixin button-sizes ($button) {
    @each $breakpoint, $size in $button {
        @include media-breakpoint('#{$breakpoint}-up') {
            // Normal styles
            @each $name, $value in $size {
                @if $name == 'padding' {
                    @include paddings($value);
                } @else if $name == 'border-radius' {
                    &,
                    &:after {
                        #{$name}: #{$value};
                    }
                    // &.btn--outline {
                    //     border-radius: 0;
                    // }
                    // &.btn--outline > svg rect {
                    //     @if type-of($value) == list {
                    //         rx: css-nth($value, 1);
                    //         ry: css-nth($value, 2);
                    //     } @else {
                    //         rx: #{$value};
                    //         ry: #{$value};
                    //     }
                    // }
                } @else {
                    #{$name}: #{$value};
                }
            }

            // Height
            @if map-has-key($size, 'height') {
                --button-height: #{ map-get($size, height) };
            }

            // Line height
            // @if not map-has-key($size, 'line-height') {
            //     line-height: map-get($size, height);
            // }

            // Adjust <button> element content if top padding was applied
            > .btn__content {
                @if map-has-key($size, 'padding') {
                    $padding-top: css-nth(map-get($size, padding), 1);
                    $padding-bottom: css-nth(map-get($size, padding), 3);
                    margin-top: -$padding-top;
                    margin-bottom: -$padding-bottom;
                    padding-top: $padding-top;
                    padding-bottom: $padding-bottom;
                }
                @if map-has-key($size, 'height') {
                    height: map-get($size, height);
                }
            }
        }
    }
}
