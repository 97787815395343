.home__works {
	.project {
		margin-top: var(--spacing-l);
		color: color-use(text-inverted);

		& + .project {
			margin-top: calc(var(--spacing-l) / 2);
		}

		&__inner {
			display: flex;
		}

		&__title {

		}

		p {
			margin-top: calc(var(--spacing-l) / 2);
		}

		img {
			flex: 0 0 auto;
			margin-right: calc(var(--spacing-l) * -1);
			margin-left: var(--spacing-l);
			align-self: flex-start;
		}

		&__technology {
			margin-top: calc(var(--spacing-l) / 2);

			&__title {
				font-weight: $semi-bold;
			}

			p {
				margin-top: 0;
			}
		}
	}
}