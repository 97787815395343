/**
 * Code element
 *
 * @demo
 *     <pre>&lt;input type="submit" /&gt;</pre>
 */

code, kbd, pre, samp {
    @extend %text--size-code;
    color:       #333;
    font-family: $text-code-font;
}

code,
pre {
    display: inline-block;
    border-radius: 3px;
    background: #000;

    color: #fff;
    font-size: 1.2rem;
    font-weight: $normal;
    letter-spacing: 0;
    text-transform: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;

    @extend %text--size-code;
}

pre {
    display: block;
    padding: 10px;
    border: 0;

    &,
    code {
        border-radius: 6px;
    }
}
code {
    padding: 2px 10px;
}

pre code,
code pre {
    font-size: inherit;
    padding: 0;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
}


/*
 * Code element decoration
 */

/*
pre {
    position: relative;

    &:before {
        position: absolute;
        right: 0;
        top: 0;
        content: "<>";
        border-radius: 0 1px 0 3px;
        padding: 2px 8px 3px 9px;
        font-family: monospace;
        background: color-use(line);
    }
}
*/
