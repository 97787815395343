.form-message {
    @include margins-top-bottom((
        xs: var(--spacing-s),
    ));

    color: color-use(small);

    a {
        color: color-use(text-inverted);
    }
}
