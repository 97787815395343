/**
 * Secondary call to action button style modifier
 *
 * 1. Increase specificity to prevent `.ui-dark a` from overwriting styles
 * 2. Hover animation effect background color
 *
 * @demo
 *     <a class="btn btn--secondary"><span class="btn__content">Normal state</span></a>
 *     <a class="btn btn--secondary is-active"><span class="btn__content">Active state</span></a>
 *     <a class="btn btn--secondary is-disabled"><span class="btn__content">Disabled state</span></a>
 */

.btn.btn--outline { // 1.
    border: 1px solid currentColor;

    $styles: (
        // Default styles
        'default': (
            color:            currentColor,
            background:       transparent,
            hover-background: transparent,
        ),
        // Style when button is hovered or pressed down
        'hover down': (
            color:      color-use(small),
        ),

        // .is-active styles
        // 'active': (
        //     color:      $color-dark-heading,
        //     background: $color-dark-background,
        //     hover-background: lighten($color-dark-background, 15%), // 2.
        // ),

        // Style when button is hovered or pressed down and has .is-active classname
        // 'active+hover active+down': (
        //     color:      $color-dark-heading,
        // ),
    );

    @include button-colors ($styles);
}
