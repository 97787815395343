/**
 * Text style mixin and placeholder classnames
 *
 * Used to keep all standard text and all headings consistent without code
 * duplication / to improve maintainability
 *
 * @see settings/_text.scss
 * @see components/text/_typography.scss
 * @demo
 *     body  { @extend %text--style-default; }
 *     h1    { @extend %text--style-heading; }
 *
 *     body  { @include text--style-default(); }
 *     h1    { @include text--style-heading(); }
 */

@mixin text--style-default () {
    font-family: $text-default-font;
    font-weight: $text-default-weight;
    text-transform: $text-default-transform;
}

@mixin text--style-heading () {
    font-weight: $text-heading-weight;
    text-transform: $text-heading-transform;
}

@mixin text--style-caption () {
    font-weight: $text-caption-weight;
    text-transform: $text-caption-transform;
}


%text--style-default {
    @include text--style-default();
}

%text--style-heading {
    @include text--style-heading();
}

%text--style-caption {
    @include text--style-caption();
}
