@import "./../mixins/components/grid";

/**
 * Grid system
 * Based on http://flexboxgrid.com/
 */

/**
 * Grid row
 *
 * @demo
 *     <div class="row">
 *         <div class="col--xs-6"></div>
 *         <div class="col--xs-6"></div>
 *     </div>
 *
 *     <div class="row row--pad">
 *         <div class="col">Left side</div>
 *         <div class="col end-xs">Right side</div>
 *     </div>
 *
 *     <div class="row row--pad">
 *         <div class="col--xs-12 col--md-6 col--xl-4"></div>
 *         <div class="col--xs-12 col--md-6 col--xl-4"></div>
 *         <div class="col--xs-12 col--md-6 col--xl-4"></div>
 *     </div>
 */

.row {
    @include grid-row ();
}

.row--pad {
    // Doesn't work if grid columns are using "vw" unit for width
    @if (unit(map-get-deep($grid-columns, 'xs', 'unit')) != 'vw') {
        @include grid-row-pad ($grid-columns);
    }
}


/**
 * Grid columns
 *
 * @demo
 *     <div class="row row--pad">
 *         <div class="col">Left side</div>
 *         <div class="col">Right side</div>
 *     </div>
 */

@include grid-columns ($grid-columns);

.col {
    // Alias
    @extend .col--xs !optional;
}


/**
 * Grid row and column alignment
 *
 * Creates alignment / order classnames:
 *   .row--start-BREAKPOINT
 *   .row--center-BREAKPOINT
 *   .row--end-BREAKPOINT
 *   .row--top-BREAKPOINT
 *   .row--middle-BREAKPOINT
 *   .row--bottom-BREAKPOINT
 *   .row--around-BREAKPOINT
 *   .row--between-BREAKPOINT
 *
 * @demo
 *     <div class="row row--start-xs"></div>
 *     <div class="row row--center-xs around-xs"></div>
 *     <div class="row row--end-xs"></div>
 */

// .row {
//     @include grid-alignment ($grid-columns);
// }


/**
 * Overwrite col--lg and col--xl with col--xxl values when
 * screen resolution is >= 1440px.
 * ONLY for "vw" layout and if >= 1440px layout is not "vw"
 */

$grid-xl-unit: unit(map-get-deep($grid-columns, 'xl', 'unit'));
$grid-xxl-unit: unit(map-get-deep($grid-columns, 'xxl', 'unit'));

@if ($grid-xl-unit == 'vw' and $grid-xxl-unit != 'vw') {
    @include media-breakpoint(xxl-up) {
        $columns: map-get-deep($grid-columns, xxl, columns);
        $unit: map-get-deep($grid-columns, xxl, unit);

        // List of breakpoint which to overwrite
        $breakpoints: lg xl;

        @each $breakpoint in $breakpoints {
            @for $i from 1 through $columns {
                $width: $i / $columns * $unit;

                .col--#{ $breakpoint }-#{$i} {
                    @include grid-column($width);
                }
                .offset--#{ $breakpoint }-#{$i} {
                    @include grid-offset($width);
                }
            }

            .col--#{ $breakpoint }-full {
                @include grid-column(100%);
            }
            .offset--#{ $breakpoint }-0 {
                @include grid-offset(0);
            }

            // Column wihch takes as little space as needed
            .col--#{ $breakpoint }-auto {
                @include grid-column-auto(100%);
            }

            // Column fills all available space
            .col#{ $breakpoint }-fill {
                @include grid-column-fill(100%);
            }
        }

    }
}
