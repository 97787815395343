.home__hire {
	background-color: #B7BABE;
	color: color-use(text-inverted);
	padding-bottom: var(--spacing-l);

	form {
		margin-top: var(--spacing-l);
	}

	.form-group {
		max-width: 880px;
	}

	.form-control input {
		color: color-use(text-inverted);
	}
}