.home__partners {
	.partner {
		margin-top: var(--spacing-l);

		& + .partner {
			margin-top: calc(var(--spacing-l) * 1.5);
		}

		&__intro {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				flex: 0 0 auto;
				margin-right: var(--spacing-l);
			}
		}

		&__title {
			@include text--size((
				xs: ( 'font-size': 7.0rem,  'line-height': 84 / 70 * 1em )
			));
		}

		&__short-descr {
			margin-top: calc(var(--spacing-l) / 4);

			@include text--size((
				xs: ( 'font-size': 3.6rem,  'line-height': 44 / 36 * 1em )
			));
		}

		&__img {
			margin: calc(var(--spacing-l) / 2) 0;

			img {
				width: 100%;
			}
		}

		&__descr {
			text-align: center;

			@extend %text--size-large;
		}
	}
}