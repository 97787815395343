/**
 * Disable all animations
 *
 * @markup
 *   <div class="disable-transitions">...</div>
 */

.disable-transitions,
.disable-transitions:before,
.disable-transitions:after,
.disable-transitions *,
.disable-transitions *:before,
.disable-transitions *:after {
    transition: none !important;
}


/*
 * Immediately jump any animation to the end point if the user has set their device to "prefers reduced motion" or if system is not
 * capable of running animations smoothly.
 *
 * This could create bad unintended consequences. Remove as needed, and write your own appropriate code for prefers-reduced-motion.
 *
 * Source: https://css-tricks.com/levels-of-fix/
 */
 @media (prefers-reduced-motion: reduce), (update: slow) {
    *,
    *:before,
    *:after {
        animation-delay: -1ms !important;
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}
