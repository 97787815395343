@import "./../mixins/components/group";

/**
 * Group of items with spacing between them
 *
 * @demo
 *   <div class="group">
 *     <span>Text</span>
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 */

.group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
	justify-content: flex-start;

    // @include group ((
    //     xs: 10px 30px
    // ));

    @include group (10px 30px);
}


/**
 * Group without spacing between items
 *
 * @demo
 *   <div class="group group--none">
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 */

.group--none {
	@include group (0);
}


/**
 * Group with small spacing between items
 *
 * @demo
 *   <div class="group group--small">
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 */

.group--small {
	@include group ((
        xs: 0 10px,
    ));
}

.group--sm {
	@include group ((
        xs: 0 var(--spacing-s),
    ));
}

.group--filter {
	@include group ((
        xs: 0 5px,
    ));
}

.group--extras {
	@include group (10px);
}


/**
 * Group item alignment
 *
 * @demo
 *   <div class="group group--middle">
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 *   <div class="group group--center">
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 *   <div class="group group--right">
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 */

// vertically in the middle
.group--middle {
	align-items: center;
}

// horizontally in the center
.group--center {
	justify-content: center;
}

// horizontally on the right side
.group--right {
	justify-content: flex-end;
}

// vertically in the end
.group--end {
	align-items: flex-end;
}


/**
 * Group item stretching
 *
 * @demo
 *   <div class="group">
 *     <a class="btn group__fill">Left aligned button</a>
 *     <a class="btn">Right aligned button</a>
 *   </div>
 */

.group__fill {
    // flex-grow: 1;
    flex: 1;
}


/**
 * Single right aligned item
 *
 * @demo
 *   <div class="group">
 *     <a class="btn">Left aligned button</a>
 *     <a class="btn group__right">Right aligned button</a>
 *   </div>
 */

.group > .group__right {
    margin-left: auto;
}


/**
 * Prevent group from wrapping
 *
 * @demo
 *   <div class="group group--nowrap">
 *     <a class="btn">Button</a>
 *     <a class="btn">Button</a>
 *   </div>
 */

.group--nowrap {
	flex-wrap: nowrap;
}

.group--column {
    flex-direction: column;
}

.group--between {
    justify-content: space-between;
}

.group--100-height {
    &,
    & > div {
        align-self: stretch;
        flex-grow: 1;
        min-height: 100%;
    }
}

.group--full {
    width: 100%;
}

.group--smalle {
	@include group ((
        xs: 0 6px,
        lg: 0 8px,
    ));
}

.group--smallest {
	@include group ((
        xs: 0 5px,
    ));
}
.group--row-reverse {
    flex-direction: row-reverse;
}
