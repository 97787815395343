/**
 * Heading styles/sizes for elements
 *
 * @demo
 *     <h2 class="h1">Looks like first level heading</h2>
 */

.h1 {
    @extend h1;
}
.h2 {
    @extend h2;
}
.h3 {
    @extend h3;
}
.h4 {
    @extend h4;
}
.h5 {
    @extend h5;
}


/**
 * Font family / style
 *
 * @demo
 *     <p class="text--heading">Text with same style as heading</p>
 */

.text--heading {
    @extend %text--style-heading;
}
.text--caption {
    @extend %text--style-caption;
}


/**
 * Font sizes for elements
 *
 * @demo
 *     <p class="text--small">Small text size</p>
 *     <p><small>Small text size</small></p>
 *     <p class="text--lead">Lead intro text</p>
 */

.text--small {
    @extend %text--size-small;
}
.text--lead {
    @extend %text--size-lead;
}
.text--p1 {
    @extend %text--size-p1;
}
.text--p2 {
    @extend %text--size-p2;
}

/**
 * Text colors for elements
 *
 * @demo
 *     <p class="text--color-primary">Primary color</p>
 *     <p class="text--color-heading">Heading color</p>
 *     <p class="text--color-text">text color</p>
 */

.text--color-accent {
    color: color-use(accent);
}

.text--color-secondary {
    color: color-use(secondary);
}

.text--color-heading {
    color: color-use(heading);
}

.text--color-small {
    color: color-use(small);
}

.text--color-text {
    color: color-use(text);
}

.text--color-background {
    color: color-use(background);
}

@include media-breakpoint(print) {
    .text--color-accent,
    .text--color-heading,
    .text--color-small,
    .text--color-text {
        & {
            color: color-use(print);
        }
    }
}


/**
 * Text alignment
 *
 * @demo
 *     <p class="text--left">Left aligned text</p>
 *     <p class="text--center">Center aligned text</p>
 *     <p class="text--right">Right aligned text</p>
 */

.text--left {
    text-align: left;
}
.text--right {
    text-align: right;
}
.text--center {
    text-align: center;
}


/**
 * Prevent text wrapping
 *
 * @demo
 *     <p class="text--nowrap">
 *         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
 *         pharetra sed erat convallis suscipit. Vestibulum at interdum neque.
 *     </p>
 */

.text--nowrap {
    white-space: nowrap !important;
}


/**
 * Truncate text adding dots at the end of the line if needed
 *
 * @demo
 *     <p class="text--truncate">
 *         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
 *         pharetra sed erat convallis suscipit. Vestibulum at interdum neque.
 *     </p>
 */

.text--truncate {
    white-space:   nowrap   !important;
    overflow:      hidden   !important;
    text-overflow: ellipsis !important;
}

