@import "./../../mixins/helpers/browsers";

/**
 * Checkbox button input control styles
 */

.form-control-checkbox {
    display: flex;
    align-items: center;

    // padding-top: ...;
    // padding-bottom: ...;

    // Hide input out of view
    input {
        position: absolute;
        clip-path: polygon(0px 0px,0px 0px,0px 0px,0px 0px);

        // Clip path doesn't work in IE11
        // @include browser-ie () {
        //     opacity: 0;
        // }
    }

    &__icon,
    &__label {
        position: relative;
        cursor: pointer;

        // Extended hover area
        &:after {
            content: "";
            @include layout-position-overlay(-10px -5px);
        }
    }

    &__icon {
        height: 20px;
        width: 20px;
        border: 1px solid color-use(cold-3);
        background: transparent;
        border-radius: 4px;

        //border: 1px solid color-use(cold-3);
        flex-shrink: 0;

        //@include transition((property: (border-color)));

        // Checkmark icon, visible when checkbox is checked
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 8px;
            left: 5px;
            top: 6px;

            //background: url(#{ $path-images }/common/checkbox.svg); // @TODO
            background-size: cover;

            @include transition((property: (opacity)));
            opacity: 0;
        }
    }

    &__label {
        flex-grow: 1;

        // Layout
        padding: 0 0 0 10px;

        // Text styles
        // color: color-use(heading);
    }

    // Disabled state
    input[disabled] ~ &__icon,
    input[disabled] ~ &__label {
        opacity: 0.5;
        pointer-events: none;
    }

    // Active state
    // .has-hover &:hover .form-control-checkbox__icon,
    // input:checked ~ &__icon,
    // input:focus ~ &__icon,
    // .has-hover &:hover input:checked ~ &__icon {
    //     border-color: ...;
    // }

    input:checked ~ &__icon {
        background: color-use(cold-3);

        &:before {
            opacity: 1;
        }
    }
}


/**
 * Link inside the label should be above the checkbox
 * and clickable
 */

.form-control-checkbox a {
    // @extend %link--style-...;
    position: relative;
    z-index: 1;

    .is-ie & {
        vertical-align: top;
    }
}


/**
 * Error styles
 */

.has-error .form-control-checkbox__icon {
    border-color: color-use(error);
}

.has-error .form-control-checkbox__label {
    color: color-use(error);
}
