/**
 * Form group
 *
 * Used to separate form elements
 *
 * @demo
 *     <div class="form-group">
 *         <div class="form-control form-control--input" data-plugin="inputState">
 *             <input id="my-input-id" name="my-input-name" type="text" />
 *             <label for="my-input-id" class="form-label">My Input Label</label>
 *         </div>
 *     </div>
 */

.form-group {
    @include margins-top-bottom((
        xs: var(--spacing-s),
        lg: calc(var(--spacing-s) / 20 * 35),
    ));
}

.form-group--small {
    @include margins-top-bottom((
        xs: var(--spacing-s),
    ));
}
